export const performanceTableReport = {
	tabName: "Table Performance",
	label: "Table Reports",
	title: "Track the performance of the tables and enhance your restaurant's management.",
	more: "more",
	less: "less",
	eventPercentage: "{{comparison}} than the last event",
	companiePercentage: "{{comparison}} than in the last {{days}} days",
	averageTime: "Average time",
	filters: {
		table: "All tables",
		periodOptions: {
			sevenDays: "7 days",
			fifteenDays: "15 days",
			thirtyDays: "30 days",
			sixtyDays: "60 days",
		},
		allTables: "All tables",
	},
	served: "Served",
	cards: {
		totalBilling: "Total Billing",
		peopleServed: "People Served",
		averageTicketPerTable: "Average Ticket per Table",
		averageTicketPerPerson: "Average Ticket per Person",
		averageSpins: "Average Spins",
		AverageLengthOfStay: "Average Length of Stay",
		tableCombinations: "Table Combinations",
		nextPage: "Next",
		previousPage: "Previous",
	},
	table: {
		title: "Table Consumption",
		columns: {
			tableName: "TABLE",
			employeeName: "WAITER",
			numberOfSeats: "PEOPLE",
			openedAt: "OPENED AT",
			permanence: "STAY TIME",
			tip: "SERVICE",
			productsValue: "PRODUCTS",
			closedAt: "CLOSED AT",
			discount: "DISCOUNTS",
			refundValue: "REFUNDS",
			totalValue: "TOTAL",
			products: {
				imageUrl: "PHOTO",
				sku: "SKU",
				name: "PRODUCT NAME",
				quantity: "QUANTITY",
				unitPrice: "UNIT PRICE",
				subTotal: "SUBTOTAL",
				discount: "DISCOUNTS",
				total: "TOTAL PRICE",
			},
		},
	},
};
