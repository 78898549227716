export const performanceTableReport = {
	tabName: "Performance das mesas",
	label: "Relatórios de mesas",
	title: "Acompanhe o desempenho das mesas e potencialize a gestão do seu restaurante.",
	averageTime: "Tempo médio",
	filters: {
		table: "Todas as mesas",
		periodOptions: {
			sevenDays: "7 dias",
			fifteenDays: "15 dias",
			thirtyDays: "30 dias",
			sixtyDays: "60 dias",
		},
		allTables: "Todas as mesas",
	},
	more: "mais",
	less: "menos",
	eventPercentage: "A {{comparison}} que o último evento",
	companiePercentage: "A {{comparison}} que nos últimos {{days}} dias",
	served: "Atendidos",
	cards: {
		totalBilling: "Faturamento total",
		peopleServed: "Pessoas atendidas",
		averageTicketPerTable: "Ticket médio por mesa",
		averageTicketPerPerson: "Ticket médio por pessoa",
		averageSpins: "Média de giros",
		AverageLengthOfStay: "Tempo médio de permanência",
		tableCombinations: "Combinações de mesas",
		nextPage: "Próximo",
		previousPage: "Anterior",
	},
	table: {
		title: "Consumo de mesas",
		columns: {
			tableName: "MESA",
			employeeName: "GARÇOM",
			numberOfSeats: "PESSOAS",
			openedAt: "ABERTURA",
			permanence: "PERMANÊNCIA",
			tip: "SERVIÇO",
			productsValue: "PRODUTOS",
			closedAt: "FECHAMENTO",
			discount: "DESCONTOS",
			refundValue: "ESTORNOS",
			totalValue: "TOTAL",
			products: {
				imageUrl: "FOTO",
				sku: "SKU",
				name: "NOME DO PRODUTO",
				quantity: "QUANTIDADE",
				unitPrice: "VALOR UNITÁRIO",
				subTotal: "SUBTOTAL",
				discount: "DESCONTOS",
				total: "VALOR TOTAL",
			},
		},
	},
};
